import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Input } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";

const metadata = {
  CREDIT: "Credit",
  CASH: "Cash",
  BANK: "Bank",
  CHEQUE: "Cheque",
};

const radioBtnOptions = [
  metadata.CASH,
  metadata.CREDIT,
  metadata.BANK,
  metadata.CHEQUE,
];

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const HandleFilterOption = ({
  filterType,
  inputbankData,
  handleBankChange,
  inputchequeData,
  handleChequeChange,
}) => {
  return (
    <div>
      {filterType === metadata.BANK ? (
        <div>
          <Input
            input={inputbankData.bankname}
            handleChange={handleBankChange}
          />
          <Input
            input={inputbankData.branchname}
            handleChange={handleBankChange}
          />
          <Input
            input={inputbankData.accountnumber}
            handleChange={handleBankChange}
          />
        </div>
      ) : filterType === metadata.CHEQUE ? (
        <div className="flex flex-col items-center">
          <Input
            input={inputchequeData.chequenumber}
            handleChange={handleChequeChange}
          />
          <Input
            input={inputchequeData.dateofcheque}
            handleChange={handleChequeChange}
          />
          <Input
            input={inputchequeData.bankname}
            handleChange={handleChequeChange}
          />
          <Input
            input={inputchequeData.refnumber}
            handleChange={handleChequeChange}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

function SupplierPaymentModal({
  handleClose,
  purchasingOrderId,
  visible,
  handleOpenImport,
}) {
  const inputDataStructure = {
    invoicenumber: {
      key: "invoicenumber",
      label: "Supplier Invoice Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    totalamount: {
      key: "totalamount",
      label: "Total Amount",
      data: "",
      required: true,
      disabled: true,
      type: "number",
      error: null,
    },
    vatprecentage: {
      key: "vatprecentage",
      label: "Vat(%)",
      data: "",
      type: "number",
      error: null,
    },
    transportcost: {
      key: "transportcost",
      label: "Transport Cost",
      data: "",
      type: "number",
      error: null,
    },
    othercost: {
      key: "othercost",
      label: "Other Cost",
      data: "",
      type: "number",
      error: null,
    },
    paidamount: {
      key: "paidamount",
      label: "Paid Amount",
      data: "",
      required: true,
      type: "number",
      error: null,
    },
    paiddate: {
      key: "paiddate",
      label: "Paid Date",
      data: "",
      required: true,
      type: "Date",
      error: null,
    },
  };
  const inputDataBankStructure = {
    bankname: {
      key: "bankname",
      label: "Bank Name",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    branchname: {
      key: "branchname",
      label: "Branch Name",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    accountnumber: {
      key: "vatprecentage",
      label: "Account Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
  };
  const inputDataChequeStructure = {
    chequenumber: {
      key: "chequenumber",
      label: "Cheque Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    dateofcheque: {
      key: "dateofcheque",
      label: "Date on cheque",
      data: "",
      required: true,
      type: "date",
      error: null,
    },
    bankname: {
      key: "bankname",
      label: "Bank Name",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    refnumber: {
      key: "refnumber",
      label: "Ref Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
  };

  const [isLoading, setIsLoading] = useState(false);

  const [netAmount, setNetAmount] = useState(0);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [inputbankData, setInputBankData] = useState(inputDataBankStructure);
  const [inputchequeData, setInputChequeData] = useState(
    inputDataChequeStructure
  );
  const [filterType, setFilterType] = useState(metadata.CASH);

  const handleRadioClick = useCallback((selectedOption) => {
    setFilterType(selectedOption);
  }, []);

  const handleChange = (input) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };
  const handleBankChange = (input) => {
    setInputBankData((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };
  const handleChequeChange = (input) => {
    setInputChequeData((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };

  const getTotalCost = useCallback(async () => {
    try {
      var total = 0;
      const poDetailsList = await apiService.post("purchsingOrder/podetails2", {
        id: purchasingOrderId,
      });

      for (const poObj of poDetailsList) {
        total += parseFloat(poObj.cost);
      }

      var inputList = { ...inputs };
      inputList.totalamount.data = total;

      setInputs(inputList);
    } catch (err) {
      console.log(err);
    }
  }, [inputs, purchasingOrderId]);

  useEffect(() => {
    getTotalCost();
  }, [getTotalCost]);

  const modalRef = useRef(null);

  const calculateTotal = useCallback(() => {
    const totalAmount = parseFloat(inputs.totalamount.data);
    const vatCost =
      (totalAmount * parseFloat(~~inputs.vatprecentage.data)) / 100;
    const transportCost = parseFloat(~~inputs.transportcost.data);
    const otherCost = parseFloat(~~inputs.othercost.data);

    const totalNetCostAmount =
      totalAmount + vatCost + transportCost + otherCost;

    setNetAmount(~~totalNetCostAmount);
  }, [
    inputs.othercost.data,
    inputs.totalamount.data,
    inputs.transportcost.data,
    inputs.vatprecentage.data,
  ]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (filterType === metadata.BANK) {
      if (inputbankData.accountnumber.data.length <= 0) {
        toast.error("Account Number is required");
        return;
      }
      if (inputbankData.bankname.data.length <= 0) {
        toast.error("Bank Name is required");
        return;
      }
      if (inputbankData.branchname.data.length <= 0) {
        toast.error("Branch Name is required");
        return;
      }
    }
    if (filterType === metadata.CHEQUE) {
      if (inputchequeData.bankname.data.length <= 0) {
        toast.error("Bank Name is required");
        return;
      }
      if (inputchequeData.chequenumber.data.length <= 0) {
        toast.error("Cheque Nmber is required");
        return;
      }
      if (inputchequeData.dateofcheque.data.length <= 0) {
        toast.error("Date of Cheque is required");
        return;
      }
      if (inputchequeData.refnumber.data.length <= 0) {
        toast.error("Ref Number of Cheque is required");
        return;
      }
    }
    var overPaidAmount = 0;
    const paidAmount = parseFloat(~inputs.paidamount.data);

    if (netAmount < paidAmount) {
      overPaidAmount = paidAmount - netAmount;
    }

    try {
      const paymentData = {
        invoicenumber: inputs.invoicenumber.data,
        totalAmount: inputs.totalamount.data,
        vatprecentage: inputs.vatprecentage.data,
        transportcost: inputs.transportcost.data,
        othercost: inputs.othercost.data,
        paidamount: inputs.paidamount.data,
        overPaidAmount: overPaidAmount,
        netAmount: netAmount,
        paiddate: inputs.paiddate.data,
      };

      const bankData = {
        bankname: inputbankData.bankname.data,
        branchname: inputbankData.branchname.data,
        accountnumber: inputbankData.accountnumber.data,
      };

      const chequeData = {
        chequenumber: inputchequeData.chequenumber.data,
        dateofcheque: inputchequeData.dateofcheque.data,
        bankname: inputchequeData.bankname.data,
        refnumber: inputchequeData.refnumber.data,
      };

      const data = {
        paymentData: paymentData,
        bankData: bankData,
        chequeData: chequeData,
        type: filterType,
        poId: purchasingOrderId,
      };

      setIsLoading(true);
      await apiService.post("payment", data);
      toast.success("Payment Data updated successfully!");
      setIsLoading(false);
      handleClose();
      handleOpenImport();
    } catch (err) {
      setIsLoading(false);
      toast.error(err);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Supplier Payment</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <div className="flex flex-col items-start py-2">
            <Input input={inputs.invoicenumber} handleChange={handleChange} />
            <Input
              disabled={true}
              input={inputs.totalamount}
              handleChange={handleChange}
            />
            <Input input={inputs.vatprecentage} handleChange={handleChange} />
            <Input input={inputs.transportcost} handleChange={handleChange} />
            <Input input={inputs.othercost} handleChange={handleChange} />
          </div>
          <div className="flex items-start justify-between p-2 border rounded-lg border-black">
            <div className="font-medium text-lg">Net Total:</div>
            <div>
              {" "}
              {netAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "LKR",
              })}
            </div>
          </div>
          <div className="flex flex-col items-start justify-center py-2">
            <Input input={inputs.paidamount} handleChange={handleChange} />
            <Input input={inputs.paiddate} handleChange={handleChange} />
          </div>

          <FilteredOptions
            options={radioBtnOptions}
            selectedOption={filterType}
            handleChange={handleRadioClick}
          />

          <HandleFilterOption
            filterType={filterType}
            handleBankChange={handleBankChange}
            handleChequeChange={handleChequeChange}
            inputbankData={inputbankData}
            inputchequeData={inputchequeData}
          />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={"Submit"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default SupplierPaymentModal;
