import React from "react";
import {
  FaBusinessTime,
  FaRegBuilding,
  FaShoppingCart,
  FaCog,
} from "react-icons/fa";
import { GoLaw } from "react-icons/go";

import { MdWork } from "react-icons/md";

import SingleCounter from "./SingleCounter";

const CounterSection = ({ countData }) => {
  const counts = [
    {
      title: "Jobs",
      count: countData.inqueryCount,
      icon: <GoLaw />,
    },

    {
      title: "Supervisors",
      count: countData.supervisorCount,
      icon: <FaCog />,
    },
    {
      title: "Materials",
      count: countData.materialCount,
      icon: <FaShoppingCart />,
    },
    {
      title: "Other Jobs",
      count: countData.otherJobCount,
      icon: <FaBusinessTime />,
    },

    {
      title: "Contractors",
      count: countData.contractorCount,
      icon: <MdWork className="text-white" />,
    },
    {
      title: "Suppliers",
      count: countData.supplierCount,
      icon: <FaRegBuilding />,
    },
  ];

  return (
    <div className="flex flex-wrap">
      {counts.map((item, index) => {
        return <SingleCounter key={index} item={item} />;
      })}
    </div>
  );
};

export default CounterSection;
