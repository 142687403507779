import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
  FaBars,
  FaBriefcase,
  FaCashRegister,
  FaCogs,
  FaDollyFlatbed,
  FaHardHat,
  FaHome,
  FaMoneyBillWave,
  FaNetworkWired,
  FaRegUser,
  FaUnity,
  FaUserAlt,
  FaUserCircle,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import { Confirm } from "../ui";
import PasswordModal from "./PasswordModal";
import { useSelector } from "react-redux";

import { MdMenu } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import CollapseItem from "../SideBar/CollapseItem";
import ListItem from "../SideBar/listItem";

const NavBar = ({ section }) => {
  const user = useSelector((state) => state.user);

  const [userMenu, setUserMenuState] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [passwordModalState, setPasswordModalState] = useState(false);

  const userMenuclassList = () => {
    let classList =
      "absolute z-10 right-0 w-48 mt-2 py-2 bg-white rounded-md shadow-xl transform transition-all duration-300 ";
    classList += userMenu ? "opacity-100 scale-100" : "opacity-0 scale-95";
    return classList;
  };

  const NavbarData = () => {
    const auth = user.authenticated;

    if (auth) {
      const isSupervisor = user.data.role === "supervisor";
      const isAdmin = user.data.role === "admin";
      const isOffice = user.data.role === "office";
      const isSales = user.data.role === "sales";

      const navList = [
        {
          title: "Home",
          url: "/",
          icon: <FaHome />,
          selected: section === "home",
          isCollapse: false,
        },
        !isSupervisor &&
          !isSales &&
          !isOffice && {
            title: "Contractors",
            url: "/contractors",
            icon: <FaBriefcase />,
            selected: section === "contractors",
            isCollapse: false,
          },
        !isSales && {
          title: "Expense types",
          url: "/expense-types",
          icon: <FaUnity />,
          selected: section === "expense-types",
          isCollapse: false,
        },
        !isSupervisor &&
          !isSales &&
          !isOffice && {
            title: "Suppliers",
            url: "/suppliers",
            icon: <FaCashRegister />,
            selected: section === "suppliers",
            isCollapse: false,
          },
        !isSales && {
          title: "Supervisors",
          url: "/supervisors",
          icon: <FaHardHat />,
          selected: section === "supervisors",
          isCollapse: false,
        },
        !isSupervisor &&
          !isSales && {
            title: "Customers",
            url: "/customers",
            icon: <FaUserAlt />,
            selected: section === "customers",
            isCollapse: false,
          },
        !isSupervisor &&
          !isSales && {
            title: "Materials",
            url: "/materials",
            icon: <FaBars />,
            selected: section === "materials",
            isCollapse: false,
          },
        !isSupervisor &&
          !isSales &&
          !isOffice && {
            title: "Expenses",
            url: "/expenses",
            icon: <FaMoneyBillWave />,
            selected: section === "expenses",
            isCollapse: false,
          },
        {
          title: "Inquries",
          url: "/jobs",
          icon: <FaNetworkWired />,
          selected: section === "jobs",
          isCollapse: false,
        },
        {
          title: "Jobs",
          url: "/inquiries",
          icon: <FaDollyFlatbed />,
          selected: section === "inquiries",
          isCollapse: false,
        },
        {
          title: "Other Jobs",
          url: "/otherjobs",
          icon: <FaDollyFlatbed />,
          selected: section === "otherJobs",
          isCollapse: false,
        },
        !isSales && {
          title: "Maintance",
          url: "/maintance",
          icon: <FaCogs />,
          selected: section === "maintance",
          isCollapse: false,
        },
        !isSupervisor &&
          !isSales && {
            title: "Reports",
            url: "/reports",
            icon: <HiDocumentReport />,
            selected: section === "reports",
            isCollapse: true,
            collapseData: [
              {
                name: "Job",
                url: "/reports/job",
              },
              {
                name: "Other Job",
                url: "/reports/other-jobs",
              },
              {
                name: "Expence",
                url: "/reports/expence",
              },
              {
                name: "Material",
                url: "/reports/materials",
              },
              {
                name: "Stock",
                url: "/reports/stock",
              },
              {
                name: "Payments",
                url: "/reports/payments",
              },
              {
                name: "Outstandings",
                url: "/reports/outstandings",
              },
              {
                name: "Balance",
                url: "/reports/balance",
              },
            ],
          },
        isAdmin && {
          title: "User Roles",
          url: "/userroles",
          icon: <FaRegUser />,
          selected: section === "users",
          isCollapse: false,
        },
      ].filter(Boolean);
      return (
        <div className="drawer">
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content">
            {/* Page content here */}

            <label htmlFor="my-drawer" className="btn btn-ghost drawer-button">
              <MdMenu className="text-white text-lg font-bold cursor-pointer" />
            </label>
          </div>
          <div className="drawer-side">
            <label
              htmlFor="my-drawer"
              aria-label="close sidebar"
              className="drawer-overlay"
            ></label>
            <ul className="space-y-2 menu bg-primary min-h-full text-base-content w-80 ">
              {navList.map(
                (item) =>
                  item !== null &&
                  (item.isCollapse ? (
                    <CollapseItem key={item.url} item={item} />
                  ) : (
                    <ListItem key={item.url} item={item} />
                  ))
              )}
            </ul>
          </div>
        </div>
      );
    }

    return <></>;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isOutside = !event.target.closest(".user-menu");

      if (isOutside && userMenu) {
        setUserMenuState(false);
      }
    };

    if (userMenu) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [userMenu]);

  return (
    <div>
      <ToastContainer />
      {logoutConfirm && (
        <Confirm
          cancelHandler={() => {
            setLogoutConfirm(false);
          }}
          confirmHandler={() => {
            window.location = "/logout";
          }}
          actionLabel="OK"
          title="Logout"
          confirmMsg="Logout from the system?"
        />
      )}
      {passwordModalState && (
        <PasswordModal
          handleClose={() => {
            setPasswordModalState(false);
          }}
        />
      )}
      <div className="bg-img h-14"></div>
      <nav className=" drop-blur-lg bg-white bg-opacity-40 backdrop-blur-lg header-nav z-30 fixed top-0 left-0 w-full  h-16  border-b-2 border-slate-500">
        <div className="w-[95%] mx-auto flex justify-between items-center navbar">
          <div className="navbar-start lg:hidden">
            <NavbarData />
          </div>
          <div className="navbar-center">
            <a className="flex" href="/">
              <img
                alt=""
                className="h-[50px]"
                src={require("../../assets/img/logo2.png")}
              ></img>
            </a>
          </div>
          <div className="navbar-end">
            {user.authenticated && (
              <div
                className="relative cursor-pointer"
                onClick={() => {
                  setUserMenuState(true);
                }}
              >
                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                  <div>
                    <FaUserCircle />
                  </div>
                </IconContext.Provider>
                {userMenu && (
                  <div className={userMenuclassList()}>
                    <span
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        setPasswordModalState(true);
                      }}
                    >
                      Change password
                    </span>
                    <span
                      onClick={() => {
                        setLogoutConfirm(true);
                      }}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Logout
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
