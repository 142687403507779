import React from "react";

import companyLogo from "../../../assets/print-assets/logo2.png";
import backgroundImage from "../../../assets/print-assets/docbg2.png";
import footerImage from "../../../assets/print-assets/docFooter.png";

const Invoice = React.forwardRef((props, ref) => {
  const {
    customerData,
    description,
    totalQotationAmount,
    totalInvoiceAmount,
    totalOutstandingAmount,
  } = props;

  const TableHeader = () => {
    return (
      <div className="flex items-center justify-between gap-2">
        {/* company logo */}
        <div>
          <div className="w-[200px] rounded object-contain ">
            <img src={companyLogo} alt="logo" />
          </div>
        </div>
        {/* company name nd address data */}
        <div className="flex flex-col items-end">
          <h2 className="font-black text-xl text-blue-700 text-end">
            IGRID HOLDINGS (PVT) LTD
          </h2>
        </div>
      </div>
    );
  };

  const TableFooter = () => {
    return (
      <div className="h-[70px] flex items-center justify-center  overflow-hidden">
        <img src={footerImage} alt="footer" />
      </div>
    );
  };
  return (
    <div
      ref={ref}
      className="bg-contain bg-no-repeat bg-center h-screen flex"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <table className="table w-[700px]">
        <thead>
          <tr>
            <th>
              <TableHeader />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>
                <table>
                  <tbody>
                    <div className="text-wrap"></div>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>
              <TableFooter />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
});

export default Invoice;
