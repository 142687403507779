import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../../../http";
import { toast } from "react-toastify";
import { Button, Input, Select } from "../../../../components/ui";

function AddUserModel({ handleClose, selectedUser }) {
  const inputDataStructure = {
    email: {
      key: "email",
      label: "Email",
      data: "",
      type: "text",
      error: null,
      required: true,
    },
    userType: {
      key: "userType",
      label: "user Type",
      data: "office",
      optList: [
        { id: "admin", title: "Admin User" },
        { id: "office", title: "Office User" },
        { id: "supervisor", title: "Supervisor User" },
        { id: "account", title: "Accountant User" },
        { id: "sales", title: "Sales Agent" },
      ],
      error: null,
      required: true,
    },
    password: {
      key: "password",
      label: "password",
      data: "",
      type: "password",
      error: null,
      required: true,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [userType, setUserType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleUserType = (usertype) => {
    switch (usertype) {
      case "admin":
        setUserType("Admin User");
        break;
      case "office":
        setUserType("Office User");
        break;
      case "supervisor":
        setUserType("Supervisor User");
        break;
      case "account":
        setUserType("Accountant User");
        break;
      case "sales":
        setUserType("Sales Agent");
        break;
      default:
        setUserType("Office User");
        break;
    }
  };

  const buildForm = useCallback(async () => {
    if (selectedUser) {
      let input_list = { ...inputs };
      handleUserType(selectedUser.userType);
      input_list.email.data = selectedUser.email;
      input_list.userType.data = selectedUser.userType;
      input_list.userType.placeHolder = userType;

      setInputs(input_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, userType]);

  useEffect(() => {
    buildForm();
  }, [buildForm]);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      email: inputs.email.data,
      password: inputs.password.data,
      userType: inputs.userType.data,
    };

    try {
      setIsLoading(true);
      if (selectedUser) {
        await apiService.put(`user/update/${selectedUser._id}`, data);
      } else {
        await apiService.post("user/register", data);
      }
      toast.success("Users updated successfully!");
      setIsLoading(false);

      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
        <div className=" shadow-xl transition-all w-[30%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
            <h3 className="mx-auto py-1">User Creation</h3>
            <span
              className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
              onClick={handleClose}
            >
              X
            </span>
          </div>

          <div className="mt-2 mx-4">
            <Input
              disabled={selectedUser ? true : false}
              input={inputs.email}
              handleChange={handleChange}
            />
            <Input input={inputs.password} handleChange={handleChange} />
            <Select input={inputs.userType} handleChange={handleChange} />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserModel;
