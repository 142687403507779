import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";

import MaterialModal from "./MaterialModal";

import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import ImportMaterialModal from "./ImportMaterialModal";
import PurchsingOrderModal from "./PurchsingOrderModal";
import axios from "axios";
import PurchsingOrderImportModel from "./PurchsingOrderImportModel";
import LoadingAnimation from "../../../components/Loader";
import PurchsingOrderSingleModal from "./models/PurchsingOrderSingleModal";
import SupplierPaymentModal from "./models/SupplierPaymentModal";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};

const Materials = () => {
  // eslint-disable-next-line no-unused-vars
  const [inputs, setInputs] = useState(inputDataStructure);

  const [materials, setMaterials] = useState([]);
  const [materialsBOM, setMaterialsBOM] = useState([]);
  const [poOrders, setPoOrders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [purchsingOrderModelState, setPurchsingOrderModelState] =
    useState(false);
  const [purchsingOrderSingleModelState, setPurchsingOrderSingleModelState] =
    useState(false);
  const [purchsingOrderImportModelState, setPurchsingOrderImportModelState] =
    useState(false);
  const [importModalState, setImportModalState] = useState(false);
  const [supplierpaymentModalState, setSupplierPaymentModalState] =
    useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedPO, setSelectedPO] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleEdit = (material) => {
    setSelectedMaterial(material);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("material/" + selectedMaterial.id);
      setConfirmModalState(false);
      refreshMaterials();
      setSelectedMaterial(null);
      toast.success("Material deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    setDataModalState(false);
    setImportModalState(false);
    setConfirmModalState(false);
    setPurchsingOrderModelState(false);
    setPurchsingOrderSingleModelState(false);
    setPurchsingOrderImportModelState(false);
    setSupplierPaymentModalState(false);
    setSelectedMaterial(null);
    // setSelectedPO(null);
    refreshMaterials();
    refreshBOMMaterials();
  };

  const refreshMaterials = async () => {
    try {
      setIsLoading(true);
      const materials = await apiService.get("material");

      setMaterials(materials);
      setFilteredData(materials);
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };
  const refreshImportedBOMData = async () => {
    try {
      const materialsBOMData = await apiService.get("material/bomdata");

      setMaterialsBOM(materialsBOMData);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const refreshBOMMaterials = async () => {
    try {
      const bomMaterials = await apiService.get("purchsingOrder");

      setPoOrders(bomMaterials);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleChange = useCallback(
    (input) => {
      if (input.data === "") {
        setFilteredData(materials);
      } else {
        const data = materials.filter((item) => {
          const lowerCaseTitle = item.title.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }
    },
    [materials]
  );

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tBodyPO = poOrders.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tBodyMBOM = materialsBOM.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshMaterials();
    refreshBOMMaterials();
  }, []);

  useEffect(() => {
    refreshImportedBOMData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePDFLoad = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      const blob = response.data;

      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleImportOpn = () => {
    setPurchsingOrderImportModelState(true);
  };

  const handlepoModelOpen = async (poData) => {
    const poId = poData.purchsingOrderId;
    const data = await apiService.post("payment/po", {
      data: poId,
    });

    if (data.length > 0) {
      setPurchsingOrderImportModelState(!purchsingOrderModelState);
    } else {
      setSupplierPaymentModalState(!supplierpaymentModalState);
    }
  };

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`material/${item.id}`);
            refreshMaterials();
          } catch (e) {
            console.log(e);
          }
        }}
        className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (material) => {
    return (
      <div className="flex">
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            handleEdit(material);
          }}
        />
      </div>
    );
  };
  const poactionBtns = (material) => {
    return (
      <div className="flex">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            setSelectedPO(material);
            await handlePDFLoad(
              `${process.env.REACT_APP_BACKEND_URL}public/materialbom/${material.invoiceId}.pdf`
            );
          }}
        />

        <Button
          text="Import"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            setSelectedPO(material);

            await handlepoModelOpen(material);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "materialId",
      key: "materialId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "20%",
    },

    {
      title: "Buying Price",
      align: "center",
      width: "20%",
      render: (arg) => {
        const formattedPrice = arg.amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Unit Price",
      align: "center",
      width: "20%",
      render: (arg) => {
        const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      sorter: (a, b) => a.unitPrice - b.unitPrice,
    },
    {
      title: "Unit",
      align: "center",
      width: "30%",
      render: (arg) => {
        return (
          <div className="badge badge-success gap-2 p-4 text-lg font-bold text-white">
            {arg.unit}
          </div>
        );
      },
    },

    {
      title: "Stock Count",
      dataIndex: "stockCount",
      key: "stockCount",
      align: "center",
      width: "20%",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];
  const columnsPO = [
    {
      title: "ID",
      // dataIndex: "purchsingOrderId",
      key: "purchsingOrderId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.purchsingOrderId.localeCompare(b.purchsingOrderId),
      render: (args) => {
        const poID = args.purchsingOrderId;
        const singlepoId = args.supBasedPOId;
        return <div>{singlepoId && singlepoId !== "" ? singlepoId : poID}</div>;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (args) => {
        const date = new Date(args.PoCreatedDate);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Status",
      align: "center",
      width: "100%",
      render: (arg) => {
        const status = arg.poStatus;

        return (
          <div
            className={
              status === "pending"
                ? "badge badge-warning gap-2 p-4 text-md font-bold text-white"
                : "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {arg.poStatus.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Total Amount(LKR)",
      align: "center",
      width: "100%",
      render: (arg) => {
        const payData = arg.payData[0];

        var totalValue = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const totalAmount = payData.paymentData.totalAmount;
          totalValue = formatter.format(totalAmount);
        }

        return (
          <div
            className={
              totalValue !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {totalValue}
          </div>
        );
      },
    },
    {
      title: "Initial Paid Amount(LKR)",
      align: "center",
      width: "100%",
      render: (arg) => {
        const payData = arg.payData[0];

        var paid = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const paidAmount = payData.paymentData.paidamount;
          paid = formatter.format(paidAmount);
        }

        return (
          <div
            className={
              paid !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {paid}
          </div>
        );
      },
    },
    {
      title: "Operations",
      align: "center",
      width: "100%",
      render: (arg) => poactionBtns(arg),
    },
  ];
  const columnsMaterialsBOM = [
    {
      title: "ID",
      dataIndex: "MaterialTransactionNumber",
      key: "MaterialTransactionNumber",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },

    {
      title: "Status",
      align: "center",
      width: "60%",
      render: (arg) => {
        const status = arg.MaterialBOMType;
        return (
          <div
            className={
              "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {status.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "unit",
      align: "center",
      width: "100%",
      render: (dateString) => {
        const date = new Date(dateString);
        return <div>{date.toLocaleDateString()}</div>;
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: "20%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "20%",
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <MaterialModal
          selectedMaterial={selectedMaterial}
          handleClose={handleCloseModal}
          visible={dataModalState}
        />
      )}
      {importModalState && (
        <ImportMaterialModal
          handleClose={handleCloseModal}
          visible={importModalState}
        />
      )}
      {purchsingOrderModelState && (
        <PurchsingOrderModal
          handleClose={handleCloseModal}
          visible={purchsingOrderModelState}
        />
      )}
      {purchsingOrderSingleModelState && (
        <PurchsingOrderSingleModal
          handleClose={handleCloseModal}
          visible={purchsingOrderSingleModelState}
        />
      )}
      {purchsingOrderImportModelState && (
        <PurchsingOrderImportModel
          handleClose={handleCloseModal}
          purchasingOrderId={selectedPO.purchsingOrderId}
          isSingle={selectedPO.isSingle}
          visible={purchsingOrderImportModelState}
        />
      )}
      {supplierpaymentModalState && (
        <SupplierPaymentModal
          handleClose={handleCloseModal}
          purchasingOrderId={selectedPO.purchsingOrderId}
          visible={supplierpaymentModalState}
          handleOpenImport={handleImportOpn}
        />
      )}
      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <Button
          text={"Add New Material"}
          handleClick={() => setDataModalState(true)}
        />
        <Button
          text={"Import Material"}
          handleClick={() => setImportModalState(true)}
        />
        <Button
          text={"Purchsing Order For multiple Suppliers"}
          className={"hidden"}
          handleClick={() => setPurchsingOrderModelState(true)}
        />
        <Button
          text={"Purchsing Order For Single Supplier"}
          handleClick={() => setPurchsingOrderSingleModelState(true)}
        />

        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <div>
        <div tabIndex={0} className="collapse bg-base-200 my-4">
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">
            MaterialBOM Content
          </div>
          <div className="collapse-content overflow-x-auto">
            <div className="p-2 gap-2 items-center">
              <Table
                columns={columnsMaterialsBOM}
                dataSource={tBodyMBOM}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div tabIndex={0} className="collapse bg-base-200 my-4">
          <input type="checkbox" />
          <div className="collapse-title text-xl font-medium">
            Purchsing Order Content
          </div>
          <div className="collapse-content overflow-x-auto">
            <div className="p-2 gap-2 items-center">
              <Table
                columns={columnsPO}
                dataSource={tBodyPO}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default Materials;
