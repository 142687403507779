import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Select, Button, FileUpload } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";
import { useSelector } from "react-redux";

const JobModal = ({ handleClose, selectedJob, visible }) => {
  const inputDataStructure = {
    customerName: {
      key: "customerName",
      label: "Customer Name",
      optTitle: "Select customer",
      data: null,
      namedata: "",
      required: true,
      type: "text",
      error: null,
    },
    supervisor: {
      key: "supervisor",
      required: true,
      label: "Supervisor",
      data: null,
      type: "text",
      error: null,
    },
    customerNic: {
      key: "customerNic",
      label: "Customer NIC",
      data: "",
      type: "text",
      error: null,
    },
    customerPhoneNumber: {
      key: "customerPhoneNumber",
      required: true,
      label: "Customer Phone Number",
      data: "",
      type: "number",
      error: null,
    },
    location: {
      key: "location",
      label: "Location",
      data: "",
      type: "text",
      error: null,
    },
    file: {
      key: "file",
      name: "file",
      label: "Quatation",
      data: [],
      error: null,
    },
  };

  const modalRef = useRef(null);

  const user = useSelector((state) => state.user);



  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  useEffect(() => {
    const handleDataFill = () => {
      const customerid = inputs.customerName.data;

      if (customerid) {
        const selectdCustomer = customers.find(
          (customerData) => customerData.id === customerid
        );

        if (selectdCustomer) {
          let input_list = { ...inputs };
          input_list.customerName.namedata = selectdCustomer.title;
          input_list.customerNic.data = selectdCustomer.customerNIC;
          input_list.customerPhoneNumber.data = selectdCustomer.phoneNumber;
          input_list.location.data = selectdCustomer.address;
          setInputs(input_list);
        }
      }
    };
    handleDataFill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.customerName.data]);

  const buildForm = async () => {
    let input_list = { ...inputs };

    const supervisors = await apiService.get("supervisor");
    const customers = await apiService.get("customer");
    input_list.supervisor.optList = supervisors;
    input_list.customerName.optList = customers;

    if (selectedJob) {
      input_list.customerName.data = selectedJob.customerName;
      input_list.customerName.placeHolder = selectedJob.customerName;
      input_list.customerName.namedata = selectedJob.customerName;
      input_list.customerNic.data = selectedJob.customerNic;
      input_list.customerPhoneNumber.data = selectedJob.customerPhoneNumber;
      input_list.location.data = selectedJob.location;
      input_list.supervisor.data = selectedJob.supervisor.id;
      input_list.supervisor.placeHolder = selectedJob.supervisor.name;
    }

    setInputs(input_list);
    setCustomers(customers);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const inqData = new FormData();

    inqData.append("supervisor", inputs.supervisor.data);
    inqData.append("customerName", inputs.customerName.namedata);
    inqData.append("customerNic", inputs.customerNic.data);
    inqData.append("customerPhoneNumber", inputs.customerPhoneNumber.data);
    inqData.append("location", inputs.location.data);
    inqData.append("email", user.data.email);

    try {
      setIsLoading(true);
      if (selectedJob) {
        const data = {
          supervisor: inputs.supervisor.data,
          customerName: inputs.customerName.namedata,
          customerNic: inputs.customerNic.data,
          customerPhoneNumber: inputs.customerPhoneNumber.data,
          location: inputs.location.data,
          id: selectedJob.id,
        };

        await apiService.patch("job", data);
        toast.success("Job Edited successfully!");
      } else {
        if (inputs.file.data[0]) {
          inqData.append("file", inputs.file.data[0].data);
        } else {
          toast.error("File is required");
          return;
        }
        await apiService.post("job", inqData);
        toast.success("Job added successfully!");
      }
      setIsLoading(false);

      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {selectedJob ? "Edit " : "New "} Inquery
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <form onSubmit={handleThis} encType="multipart/form-data">
            <Select input={inputs.supervisor} handleChange={handleChange} />
            {/* here, when a person is sellected
              his id will populate the dataField, make sure to 
              get the users name to a sepearte field
            */}
            <Select
              input={inputs.customerName}
              isaddNewNeeded={true}
              handleChange={handleChange}
            />
            <Input input={inputs.customerNic} handleChange={handleChange} />
            <Input
              input={inputs.customerPhoneNumber}
              handleChange={handleChange}
            />
            <Input input={inputs.location} handleChange={handleChange} />
            {selectedJob ? null : (
              <FileUpload
                input={inputs.file}
                handleChange={handleChange}
                multiple
              />
            )}
            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={selectedJob ? "Edit" : "Create"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default JobModal;
