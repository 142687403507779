import React from "react";

import ListItem from "./listItem";

import {
  FaHome,
  FaBars,
  FaDollyFlatbed,
  FaCashRegister,
  FaNetworkWired,
  FaRegUser,
  FaBriefcase,
  FaUnity,
  FaHardHat,
  FaUserAlt,
  FaMoneyBillWave,
  FaCogs,
} from "react-icons/fa";

import { HiDocumentReport } from "react-icons/hi";
import { useSelector } from "react-redux";
import CollapseItem from "./CollapseItem";

const SideBar = ({ section }) => {
  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";
  const isAdmin = user.data.role === "admin";
  const isOffice = user.data.role === "office";
  const isSales = user.data.role === "sales";

  const navList = [
    {
      title: "Home",
      url: "/",
      icon: <FaHome />,
      selected: section === "home",
      isCollapse: false,
    },
    !isSupervisor &&
      !isSales &&
      !isOffice && {
        title: "Contractors",
        url: "/contractors",
        icon: <FaBriefcase />,
        selected: section === "contractors",
        isCollapse: false,
      },
    !isSales && {
      title: "Expense types",
      url: "/expense-types",
      icon: <FaUnity />,
      selected: section === "expense-types",
      isCollapse: false,
    },
    !isSupervisor &&
      !isSales &&
      !isOffice && {
        title: "Suppliers",
        url: "/suppliers",
        icon: <FaCashRegister />,
        selected: section === "suppliers",
        isCollapse: false,
      },
    !isSales && {
      title: "Supervisors",
      url: "/supervisors",
      icon: <FaHardHat />,
      selected: section === "supervisors",
      isCollapse: false,
    },
    !isSupervisor &&
      !isSales && {
        title: "Customers",
        url: "/customers",
        icon: <FaUserAlt />,
        selected: section === "customers",
        isCollapse: false,
      },
    !isSupervisor &&
      !isSales && {
        title: "Materials",
        url: "/materials",
        icon: <FaBars />,
        selected: section === "materials",
        isCollapse: false,
      },
    !isSupervisor &&
      !isSales &&
      !isOffice && {
        title: "Expenses",
        url: "/expenses",
        icon: <FaMoneyBillWave />,
        selected: section === "expenses",
        isCollapse: false,
      },
    {
      title: "Inquiries",
      url: "/jobs",
      icon: <FaNetworkWired />,
      selected: section === "jobs",
      isCollapse: false,
    },
    {
      title: "Jobs",
      url: "/inquiries",
      icon: <FaDollyFlatbed />,
      selected: section === "inquiries",
      isCollapse: false,
    },
    {
      title: "Other Jobs",
      url: "/otherjobs",
      icon: <FaDollyFlatbed />,
      selected: section === "otherJobs",
      isCollapse: false,
    },
    !isSales && {
      title: "Maintenance",
      url: "/maintance",
      icon: <FaCogs />,
      selected: section === "maintance",
      isCollapse: false,
    },
    !isSupervisor &&
      !isSales && {
        title: "Reports",
        url: "/reports",
        icon: <HiDocumentReport />,
        selected: section === "reports",
        isCollapse: true,
        collapseData: [
          {
            name: "Job",
            url: "/reports/job",
          },
          {
            name: "Other Job",
            url: "/reports/other-jobs",
          },
          {
            name: "Expence",
            url: "/reports/expence",
          },
          {
            name: "Material",
            url: "/reports/materials",
          },
          {
            name: "Stock",
            url: "/reports/stock",
          },
          {
            name: "Payments",
            url: "/reports/payments",
          },
          {
            name: "Outstandings",
            url: "/reports/outstandings",
          },
          {
            name: "Balance",
            url: "/reports/balance",
          },
        ],
      },
    isAdmin && {
      title: "User Roles",
      url: "/userroles",
      icon: <FaRegUser />,
      selected: section === "users",
      isCollapse: false,
    },
  ].filter(Boolean);

  return (
    <div className="hidden lg:flex px-4 drop-blur-lg overflow-y-auto bg-primary backdrop-blur-lg min-h-[calc(100vh-96px)]">
      <ul className="space-y-2  items-center w-full mt-4">
        {navList.map(
          (item) =>
            item !== null &&
            (item.isCollapse ? (
              <CollapseItem key={item.url} item={item} />
            ) : (
              <ListItem key={item.url} item={item} />
            ))
        )}
      </ul>
    </div>
  );
};
export default SideBar;
