import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../http";
import { Button, Input, Select } from "../../../../components/ui";
import { toast } from "react-toastify";

function AdvanceOnlyModel({ handleClose, inquiry, visible }) {
  const inputDataStructure = {
    amount: {
      key: "amount",
      label: "Amount (LKR)",
      data: "",
      type: "number",
      min: 0,
      error: null,
      required: true,
    },
    advanceType: {
      key: "advanceType",
      label: "Invoice Type",
      data: "cebPaid",
      optList: [
        { id: "cebPaid", title: "Paid to CEB" },
        { id: "advAmount", title: "Advance Amount" },
        { id: "estCharges", title: "Estimated Charges" },
        { id: "clearCharges", title: "LECO/CEB Clearances" },
      ],
      error: null,
      required: true,
    },
  };

  const modalRef = useRef(null);
  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  useEffect(() => {
    if (0) {
      let input_list = { ...inputs };
      input_list.amount.data = inquiry.amount;

      input_list.file.data.push({
        key: 0,
        data: inquiry.file,
      });
      // setInputs(input_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      amount: inputs.amount.data,
      id: inquiry.id,
      advanceType: inputs.advanceType.data,
    };

    // data.append("amount", inputs.amount.data);
    // data.append("id", inquiry.id);

    try {
      setIsLoading(true);

      await apiService.patch("inquiry/advanceonly", data);

      toast.success("Quotation updated successfully!");
      setIsLoading(false);

      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Paid Payment</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <form onSubmit={handleThis} encType="multipart/form-data">
            <Select input={inputs.advanceType} handleChange={handleChange} />

            <Input input={inputs.amount} handleChange={handleChange} />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default AdvanceOnlyModel;
