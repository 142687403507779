import React, { useState } from "react";
import Slider from "react-slick";

function ImageCarousal(props) {
  const { imageArray, path } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings}>
        {imageArray.map((item, index) => (
          <div key={index}>
            <img
              className="w-[400px] h-[400px] object-contain overflow-hidden cursor-pointer"
              src={`${process.env.REACT_APP_BACKEND_URL}${path}/${item.filePath}`}
              alt="sitedata"
              onClick={() =>
                openModal(
                  `${process.env.REACT_APP_BACKEND_URL}${path}/${item.filePath}`
                )
              }
            />
          </div>
        ))}
      </Slider>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              className="w-[600px] h-[600px] object-contain"
              src={selectedImage}
              alt="sitedata"
            />
            <button
              className="btn absolute top-2 right-2 text-black text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageCarousal;
