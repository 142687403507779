import React, { useState, useEffect, useRef } from "react";
import { HiOutlineX, HiOutlineChevronDown } from "react-icons/hi";

const Select = ({
  input,
  handleChange,
  isaddNewNeeded = false,
  inputClassName = "",
  labelClassName = "",
  pod = "",
  clear = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [placeHolder, setPlaceHolder] = useState(
    input.placeHolder && input.placeHolder !== ""
      ? input.placeHolder
      : "Search an item"
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const error = input.error;

  const dropdownRef = useRef(null);

  const getInputClassList = () => {
    let classes =
      "px-4 " +
      "appearance-none transition duration-300 " +
      "ease-in-out focus:outline-none " +
      "focus:ring-0 border mb-2 " +
      "border-border-base rounded-md focus:border-green-500 h-12 relative";

    classes += error ? " border-red-500" : "";
    classes += input.data
      ? " placeholder-slate-600 "
      : " placeholder-slate-400 ";
    classes += inputClassName;

    return classes;
  };

  const getLabelClassList = () => {
    let classes = "mb-2 ";

    classes += labelClassName;

    return classes;
  };

  const addupdatedValue = (data) => {
    input.namedata = data;
    handleChange(input);
  };

  const handleSearchTerm = (input) => {
    setSearchTerm(input.data);
  };

  const handleSearchValue = () => {
    return input.data != null ? input.placeHolder : searchTerm;
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setPlaceHolder("Select a value");
  }, [clear]);

  // Dynamically update filteredOptions when input.data or searchTerm changes
  useEffect(() => {
    if (input.optList) {
      setFilteredOptions(
        input.optList.filter((item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [input.data, searchTerm, input.optList]);

  return (
    <div className="w-full flex flex-col mb-1 relative">
      {input.label && (
        <label className={getLabelClassList()}>
          {input.label}
          {input.required && <span className="text-red-500 ml-2">*</span>}
        </label>
      )}

      <div className="relative inline-block w-full" ref={dropdownRef}>
        <input
          type="text"
          className={`w-full ${getInputClassList()}`}
          placeholder={placeHolder}
          value={handleSearchValue()}
          onChange={(e) => {
            if (input.data != null) {
              handleSearchTerm(input);
            } else {
              setSearchTerm(e.target.value);
            }
            setDropdownOpen(true);
            if (isaddNewNeeded) addupdatedValue(e.target.value);
          }}
          onFocus={() => setDropdownOpen(true)}
        />

        {(input.data || searchTerm.length !== 0) && (
          <span
            className="absolute text-gray-800 right-10 top-4 cursor-pointer"
            onClick={() => {
              setSearchTerm("");
              setPlaceHolder("Search an item");
              handleChange({
                ...input,
                data: null,
              });
            }}
          >
            <HiOutlineX />
          </span>
        )}

        <span className="absolute text-gray-800 right-3 top-4 cursor-pointer">
          <HiOutlineChevronDown />
        </span>

        {isDropdownOpen && (
          <div className="w-full flex flex-col max-h-48 overflow-y-auto absolute bg-white border z-10">
            {filteredOptions.map((item, index) => (
              <span
                className="px-3 py-3 hover:bg-slate-100  transition smooth border-b-[1px] border-slate-200  cursor-pointer w-full"
                onClick={() => {
                  setPlaceHolder(item.title);
                  setSearchTerm("");
                  setDropdownOpen(false);
                  if (pod !== "") {
                    handleChange({
                      ...input,
                      data: { sup: item.id, pod: pod },
                    });
                  } else {
                    handleChange({
                      ...input,
                      data: item.id,
                      placeHolder: item.title,
                    });
                  }
                }}
                key={index}
              >
                {item.title}
              </span>
            ))}
          </div>
        )}
      </div>

      {error && <p className="text-red-500 block">{error}</p>}
    </div>
  );
};

export default Select;
