import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Select, ImgUpload } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";

const metadata = {
  CASH: "Cash",
  BANK: "Bank",
};

const radioBtnOptions = [metadata.CASH, metadata.BANK];

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const HandleFilterOption = ({
  filterType,
  inputbankData,
  handleBankChange,
}) => {
  return (
    <div>
      {filterType === metadata.BANK ? (
        <div>
          <Input
            input={inputbankData.bankname}
            handleChange={handleBankChange}
          />
          <Input
            input={inputbankData.branchname}
            handleChange={handleBankChange}
          />
          <Input
            input={inputbankData.accountnumber}
            handleChange={handleBankChange}
          />
          <ImgUpload
            input={inputbankData.bankdoc}
            handleChange={handleBankChange}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
const AdvanceModal = ({ handleClose, inquiry, visible, total }) => {
  const inputDataStructure = {
    amount: {
      key: "amount",
      label: "Amount (LKR)",
      data: "",
      type: "number",
      min: 0,
      error: null,
      required: true,
    },
    advanceType: {
      key: "advanceType",
      label: "Invoice Type",
      data: "cebPaid",
      optList: [
        { id: "cebPaid", title: "Paid to CEB" },
        { id: "advAmount", title: "Advance Amount" },
        { id: "estCharges", title: "Estimated Charges" },
        { id: "clearCharges", title: "LECO/CEB Clearances" },
      ],
      error: null,
      required: true,
    },
  };
  const inputDataBankStructure = {
    bankname: {
      key: "bankname",
      label: "Receipt ID",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    branchname: {
      key: "branchname",
      label: "Date",
      data: "",
      required: true,
      type: "Date",
      error: null,
    },
    accountnumber: {
      key: "accountnumber",
      label: "Account Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    bankdoc: {
      key: "bankdoc",
      label: "Bank Document",
      data: [],
      name: "bankdoc",
      error: null,
    },
  };
  const modalRef = useRef(null);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "LKR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [inputbankData, setInputBankData] = useState(inputDataBankStructure);

  const [filterType, setFilterType] = useState(metadata.CASH);

  const [isLoading, setIsLoading] = useState(false);

  const handleRadioClick = useCallback((selectedOption) => {
    setFilterType(selectedOption);
  }, []);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleBankChange = (input) => {
    setInputBankData((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };

  useEffect(() => {
    if (0) {
      let input_list = { ...inputs };
      input_list.amount.data = inquiry.amount;

      input_list.file.data.push({
        key: 0,
        data: inquiry.file,
      });
      // setInputs(input_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (filterType === metadata.BANK) {
      if (inputbankData.accountnumber.data.length <= 0) {
        toast.error("Account Number is required");
        return;
      }
      if (inputbankData.bankname.data.length <= 0) {
        toast.error("ReceiptId required");
        return;
      }
      if (inputbankData.branchname.data.length <= 0) {
        toast.error("Date is required");
        return;
      }
    }

    const formData = new FormData();

    const data = {
      amount: inputs.amount.data,
      id: inquiry.id,
      advanceType: inputs.advanceType.data,
      bankdata: null,
    };

    const bankData = {
      bankname: inputbankData.bankname.data,
      branchname: inputbankData.branchname.data,
      accountnumber: inputbankData.accountnumber.data,
    };

    if (inputbankData.bankdoc.data[0]) {
      formData.append("bankdoc", inputbankData.bankdoc.data[0].data);
    }

    formData.append("amount", inputs.amount.data);
    formData.append("id", inquiry.id);
    formData.append("advanceType", inputs.advanceType.data);
    formData.append("bankdata", JSON.stringify(bankData));

    // data.append("amount", inputs.amount.data);
    // data.append("id", inquiry.id);

    try {
      setIsLoading(true);
      if (filterType === metadata.BANK) {
        await apiService.patch("inquiry/advance", formData);
      } else {
        await apiService.patch("inquiry/advance", data);
      }
      toast.success("Quotation updated successfully!");
      setIsLoading(false);

      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Paid Payment</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <div className="flx flex-col gap-2">
            <div className="flex gap-2 justify-center items-center">
              <div>Quotation Amount</div>
              <div>{formatter.format(inquiry.quotationAmount)}</div>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <div className="font-bold text-red-600">Total Outstanding</div>
              <div className="text-red-600">
                {formatter.format(
                  inquiry.quotationAmount - inquiry.paidAmount - total
                )}
              </div>
            </div>
          </div>
          <br></br>
          <form onSubmit={handleThis} encType="multipart/form-data">
            <Select input={inputs.advanceType} handleChange={handleChange} />

            <Input input={inputs.amount} handleChange={handleChange} />

            <FilteredOptions
              options={radioBtnOptions}
              selectedOption={filterType}
              handleChange={handleRadioClick}
            />

            <HandleFilterOption
              filterType={filterType}
              handleBankChange={handleBankChange}
              inputbankData={inputbankData}
            />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default AdvanceModal;
